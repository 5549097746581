const Oee =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAAXNSR0IArs4c6QAAAUFJREFUKFNdkD9Iw1AQxr8vpREKJql0chEHQRychK4KJc2go0VcxEkEBxfppINOxcVFHBzcRHTUIQmVjlJwchBxERenYpMKBVPak/dS8c9b7t3d7777Q/x6IpcZxGMTOmS/v5KV/nea6pMCThWQbQAfw+QowCPYUU0VUESIOLwCYMHIbNEqPeviOJyCyDGADmx3mdIO1kBswI7nyUrybxQTkd0AeEqJgjtAdul4da0U+SXAEDru7Y/PAwV20U0KHF/qSieYxgCPWtXADK3yk7xd55AzWwqMgN4kncW2dBoF9JMHDWbMWVoLLYlu8kD2RYEBiDPa5Yv0AvdZZcm5XrpUsALBOiUKXUBOYJhFpfBnGdVhkDQBbqZ3bPv7IFcBqaL3GWo4O+ICrEHknHlvT4NpC9+DcAdAcRhqgnJI2/OV/wWKEocHvIXG7QAAAABJRU5ErkJggg==';

const availability =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAAAXNSR0IArs4c6QAAAU1JREFUKFN9kE8oRGEUxX/3pZka815GCQuWUspKKWVnIatZyA6xmBlSpGbFYhayUf6lmTcWlKUSSws7UspWyZIFkTDvzdSg79P3ocbG3ZzbPadzz71CTWmNVLZoNaPYDPci6F9aTKNzOOUW5jTMA84PqQRW6x9YlxxKjEtQZE+gHYdpN8WVEYZ5urWwqR1u3RTjEhYY1Q6z8QR9MsL7nyj7RMIXzkWxIYHPmcBSPMOxEZXy9Bv0pjm1zj6DGhYl9AnDGM0tY5RL23SI4trmduj0UtzoIrGy5tE4lj6EtsY0b8EOTVS/MxKly53k6XkTLxLhzghP0BTcKQ6sU5GYQUlTMRj4DAMZKfsMKVj7jNKbmOC19piXXRrqqlwoYd7+MfRZ1kJSQdarcmKPijLgwAqKQ3eKBSu0RJGkKLIIPXagudQOK16aIxuldtV//RfkonRbdaIe+QAAAABJRU5ErkJggg==';

const quality =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALCAYAAABGbhwYAAAAAXNSR0IArs4c6QAAAXRJREFUKFNVkD9IQnEQx++eT9OgsGiNagjCocXgPaGh4GENNSol+KzFCBpaoqmGmqSlJQIbMgOLHGvI5wuX4Gnk0hDRUtEaFQlZ+vxdnCDYLXf3vc/vd38Q2ix0FnJ09T4PsFR5H3zJhrONVhk5YMDjfV0HhFUCrLCGQF1AsFv97E/wAwQCjJpqViLoJkmspLWbRwYXDGWYAPcEwtexVgyhbqoxIFqqflQmsuH72v9RfC6Pt7tAAAeo51ULEDbSWtFkSDdVDQXSUdC6auVAsM3gt/un3pecLX8v5AIjQqJ7BiSBvtSU9RA/93f+uJ1vGM2rn41feSgzc/0xX/D3OW3XHYN1uTZ6Mll+i1yM9zg67CfU84EcoTg81kqnDMRv/U72ybFynX3UVOaQpEWMGUpQoLRvyzWFf2hfhjvItqskkVhu3lE3AluAFEGgdYdHNlhrVO0gASaAMJMOWptNsHk3MzAtANaASGkKiCUJYCelWZec/gG3lJf7EnPUpwAAAABJRU5ErkJggg==';

const performance =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAMCAYAAABbayygAAAAAXNSR0IArs4c6QAAAXVJREFUKFNtkU8oRFEUxr/zhlHKn5REUTKDUmyUUop5FEpZsJQlWWDeNFIs3kIWJu8xG9naSpQF8V4UKWWr8AZFUZT8mylj5h3d99Aod3Pu951f5373XkLmYqaOufNSYe1O1NyDiH/aJDaqytJBXmwcBAUgyW2yDYbW8uZbUFWyCcwk67EVMCpswsie4j8VmLxwVc92OioBN0bQN0gB3RogYKzoJdm8qtYlM5P0q6fepwLvEQOLFNCsQwLNmIpv25mknbeIaio1B66OdTJ4mmTNevekEyU74Ya4PH9ZDbLP3IhSrRmquuhZvstNxOMPAnylpFRuTFa9dEWt4o8UnIw5WajbGvU/dkWt/GQKtxTQLgyJsWSEqtcEICaIujlUlnCO1q0+Zh6mtvnLbolsPU1ZTfvByufMy7Tq14UeTh0TseK8o6xbs2D0EnE420OG8D7T3M5MEQDrpuKfckAXjvWCOQyg8ds6AVHEDPo2hP4F/3zlP+IL7/qVH+MPwocAAAAASUVORK5CYII=';
export { Oee, availability, quality, performance };
