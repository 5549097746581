<template>
  <div class="header">
    <div class="title">{{ title }}</div>
    <div class="deviceStatus">
      <div class="statusItem" v-for="item in status" :key="item.className">
        <div :class="item.className"></div>
        <div class="statusDetail">
          <div class="statusName">{{ item.name }}</div>
          <div class="statusValue" v-if="deviceData">
            <span class="value">{{ deviceData[item.code] || 0 }}</span>
            {{ unit }}
          </div>
        </div>
      </div>
    </div>
    <div class="timePicker">
      <div class="hour">
        {{ hour }}
      </div>
      <div class="day">
        {{ day }}
      </div>
    </div>
  </div>
</template>

<script>
import { timerFormatter } from '@/axios/utils.js';
export default {
  data() {
    return {
      title: '龙璟设备可视化电子地图',
      unit: '台',
      status: [
        { name: '运行设备', className: 'running', code: 'runningDeviceSize' },
        { name: '空闲设备', className: 'free', code: 'freeDeviceSize' },
        { name: '故障设备', className: 'Alarm', code: 'alarmDeviceSize' },
        { name: '离线设备', className: 'offline', code: 'offlineDeviceSize' }
      ],
      deviceData: null,
      hour: timerFormatter('hh:mm:ss', new Date()),
      day: timerFormatter('yyyy-MM-dd', new Date()),
      timer: null,
      DeviceTimer: null
    };
  },
  created() {
    this.init();
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.DeviceTimer);
  },
  methods: {
    async init() {
      const deivceData = await this.$apis.LSD.deviceData();
      this.deviceData = deivceData;
      this.timer = setInterval(() => {
        this.hour = timerFormatter('hh:mm:ss', new Date());
        this.day = timerFormatter('yyyy-MM-dd', new Date());
      }, 1000);
      this.DeviceTimer = setInterval(async () => {
        const deivceData = await this.$apis.LSD.deviceData();
        this.deviceData = deivceData;
      }, 10 * 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-family: blod;
  margin-bottom: 25px;
}
.timePicker {
  position: absolute;
  right: 40px;
  top: 20px;
  color: #ffffff;
  text-align: right;
  .hour {
    font-size: 30px;
  }
  .day {
    font-size: 14px;
  }
}
.deviceStatus {
  display: flex;
  .statusItem {
    display: flex;
    width: 180px;
    height: 60px;
    .statusDetail {
      padding-left: 10px;
      .statusName {
        font-family: stand;
        color: #f3f3f3;
        font-size: 16px;
      }
      .statusValue {
        font-size: 10px;
        line-height: 30px;
        color: #f3f3f3;
        & > .value {
          font-size: 28px;
          font-family: MyFontName;
        }
      }
    }
    .running {
      width: 60px;
      height: 52px;
      background: url('../image/running.png') no-repeat;
      background-position: left center;
      background-size: cover;
    }
    .free {
      width: 60px;
      height: 52px;
      background: url('../image/free.png') no-repeat;
      background-position: left center;
      background-size: cover;
    }
    .Alarm {
      width: 60px;
      height: 52px;
      background: url('../image/Alarm.png') no-repeat;
      background-position: left center;
      background-size: cover;
    }
    .offline {
      width: 60px;
      height: 52px;
      background: url('../image/offline.png') no-repeat;
      background-position: left center;
      background-size: cover;
    }
  }
}
</style>
