// 轮询时间
const polling_time = 10 * 1000;
const device_list = [
  {
    name: '6#卫卷带式切纸机',
    deviceId: '3897668257790464',
    status: 'running',
    width: 58,
    height: 101,
    top: 48,
    left: 52,
    dialog: {
      left: -36,
      top: -80,
      width: 136,
      height: 91
    }
  },
  {
    name: '6#卫卷打纸复卷机',
    deviceId: '3897299924490752',
    status: 'running',
    width: 137,
    height: 81,
    top: 70,
    left: 121,
    dialog: {
      left: -36,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '双排手帕纸机',
    deviceId: '3896196313532928',
    status: 'running',
    width: 113,
    height: 153,
    top: 64,
    left: 327,
    dialog: {
      left: -20,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '3#厨房折叠机',
    deviceId: '3896231803247104',
    status: 'running',
    width: 138,
    height: 64,
    top: 149,
    left: 455,
    dialog: {
      left: -36,
      top: -70,
      width: 136,
      height: 91
    }
  },
  {
    name: '3#厨房大回旋',
    deviceId: '3897284042652160',
    status: 'running',
    width: 57,
    height: 57,
    top: 217,
    left: 542,
    dialog: {
      left: -46,
      top: -80,
      width: 136,
      height: 91
    }
  },
  {
    name: '1#厨房折叠机',
    deviceId: '3896231996176896',
    status: 'running',
    width: 132,
    height: 53,
    top: 154,
    left: 678,
    dialog: {
      left: -6,
      top: -75,
      width: 136,
      height: 91
    }
  },
  {
    name: '1#厨房大回旋',
    deviceId: '3897284198955520',
    status: 'running',
    width: 53,
    height: 66,
    top: 194,
    left: 775,
    dialog: {
      left: -16,
      top: -60,
      width: 136,
      height: 91
    }
  },
  {
    name: '2#厨房折叠机',
    deviceId: '3896231929645568',
    status: 'running',
    width: 86,
    height: 54,
    top: 214,
    left: 649,
    dialog: {
      left: -26,
      top: -60,
      width: 136,
      height: 91
    }
  },
  {
    name: '1#打浆生产线',
    deviceId: '3897666088507904',
    status: 'running',
    width: 56,
    height: 75,
    top: 479,
    left: 313,
    dialog: {
      left: -56,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '2#打浆生产线',
    deviceId: '3897666158307840',
    status: 'running',
    width: 56,
    height: 75,
    top: 388,
    left: 349,
    dialog: {
      left: -56,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '盘磨机 1',
    deviceId: '3896196892015104',
    status: 'running',
    width: 46,
    height: 82,
    top: 350,
    left: 542,
    dialog: {
      left: -56,
      top: -20,
      width: 136,
      height: 91
    }
  },
  {
    name: '锥磨机',
    deviceId: '3896196767226368',
    status: 'running',
    width: 46,
    height: 82,
    top: 350,
    left: 616,
    dialog: {
      left: -46,
      top: -75,
      width: 136,
      height: 91
    }
  },
  {
    name: '盘磨机 2',
    deviceId: '3896196893448704',
    status: 'running',
    width: 46,
    height: 96,
    top: 440,
    left: 519,
    dialog: {
      left: -56,
      top: -20,
      width: 136,
      height: 91
    }
  },
  {
    name: '5#卫卷带式切纸机',
    deviceId: '3907186677000704',
    status: 'running',
    width: 77,
    height: 58,
    top: 9,
    left: 857,
    dialog: {
      left: -6,
      top: -60,
      width: 136,
      height: 91
    }
  },
  {
    name: '5#卫卷打纸复卷机',
    deviceId: '3896239512406528',
    status: 'running',
    width: 64,
    height: 113,
    top: 37,
    left: 941,
    dialog: {
      left: -16,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '4#卫卷打纸复卷机',
    deviceId: '3896239436491264',
    status: 'running',
    width: 64,
    height: 113,
    top: 37,
    left: 1047,
    dialog: {
      left: -16,
      top: 15,
      width: 136,
      height: 91
    }
  },
  {
    name: '4#卫卷带式切纸机',
    deviceId: '3907187508349440',
    status: 'running',
    width: 77,
    height: 58,
    top: 12,
    left: 1120,
    dialog: {
      left: -36,
      top: -60,
      width: 136,
      height: 91
    }
  },
  {
    name: '4#厨房折叠机',
    deviceId: '3896232096270848',
    status: 'running',
    width: 52,
    height: 79,
    top: 36,
    left: 1235,
    dialog: {
      left: -36,
      top: -20,
      width: 136,
      height: 91
    }
  },
  {
    name: '4#厨房大回旋',
    deviceId: '3897284403272192',
    status: 'running',
    width: 75,
    height: 48,
    top: 30,
    left: 1289,
    dialog: {
      left: -36,
      top: -80,
      width: 136,
      height: 91
    }
  },
  {
    name: '5#厨房大回旋',
    deviceId: '3897285156428288',
    status: 'running',
    width: 75,
    height: 48,
    top: 30,
    left: 1408,
    dialog: {
      left: -36,
      top: -80,
      width: 136,
      height: 91
    }
  },
  {
    name: '5#厨房折叠机',
    deviceId: '3896232248830464',
    status: 'running',
    width: 70,
    height: 79,
    top: 40,
    left: 1495,
    dialog: {
      left: -26,
      top: -60,
      width: 136,
      height: 91
    }
  },
  {
    name: '6#厨房折叠机',
    deviceId: '3896232164059648',
    status: 'running',
    width: 66,
    height: 79,
    top: 40,
    left: 1581,
    dialog: {
      left: -16,
      top: -15,
      width: 136,
      height: 91
    }
  },
  {
    name: '6#厨房大回旋',
    deviceId: '3897285076847104',
    status: 'running',
    width: 75,
    height: 48,
    top: 26,
    left: 1650,
    dialog: {
      left: -22,
      top: -80,
      width: 136,
      height: 91
    }
  },
  {
    name: '白水回收机',
    deviceId: '3897666719857152',
    status: 'running',
    width: 95,
    height: 125,
    top: 180,
    left: 220,
    dialog: {
      left: -36,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '1#造纸机',
    deviceId: '3896197196683776',
    status: 'running',
    width: 336,
    height: 198,
    top: 215,
    left: 977,
    dialog: {
      left: -26,
      top: -10,
      width: 136,
      height: 91
    }
  },
  {
    name: '2#造纸机',
    deviceId: '3896197264476672',
    status: 'running',
    width: 363,
    height: 185,
    top: 422,
    left: 987,
    dialog: {
      left: -36,
      top: -10,
      width: 136,
      height: 91
    }
  },
  {
    name: '1#分切机',
    deviceId: '3896200019159552',
    status: 'running',
    width: 292,
    height: 103,
    top: 480,
    left: 1575,
    dialog: {
      left: -36,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '2#分切机',
    deviceId: '3897667700136448',
    status: 'running',
    width: 257,
    height: 90,
    top: 305,
    left: 1503,
    dialog: {
      left: -36,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '3#卫卷打纸复卷机_2',
    deviceId: '3910817238800896',
    status: 'running',
    width: 103,
    height: 108,
    top: 163,
    left: 1577,
    dialog: {
      left: 16,
      top: 10,
      width: 136,
      height: 91
    }
  },
  {
    name: '2#卫卷打纸复卷机',
    deviceId: '3897669350693376',
    status: 'running',
    width: 111,
    height: 108,
    top: 163,
    left: 1700,
    dialog: {
      left: -6,
      top: -50,
      width: 136,
      height: 91
    }
  },
  {
    name: '3#卫卷打纸复卷机_1',
    deviceId: '3910817237543424',
    status: 'running',
    width: 89,
    height: 90,
    top: 163,
    left: 1465,
    dialog: {
      left: -6,
      top: -40,
      width: 136,
      height: 91
    }
  },
  {
    name: '3#卫卷大回旋',
    deviceId: '3897281604839936',
    status: 'running',
    width: 120,
    height: 58,
    top: 177,
    left: 1318,
    dialog: {
      left: -6,
      top: -60,
      width: 136,
      height: 91
    }
  }
];

export { polling_time };
export default device_list;
