<template>
  <div class="drawer-title">{{ name }}</div>
</template>

<script>
export default {
  props: {
    name: String
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.drawer-title {
  position: relative;
  font-size: 16px;
  font-family: blod;
  font-weight: bold;
  padding-left: 8px;
  margin-bottom: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0px;
    width: 3px;
    height: 16px;
    border-radius: 1px;
    background-color: #f39800;
  }
}
</style>
